<template>
  <div class="box" :style="{backgroundColor: selectValue === '' ? 'white' : '#F9F9F9'}">

    <div class="search">
<!--      <van-nav-bar-->
<!--          title="搜索"-->
<!--          left-text=""-->
<!--          left-arrow-->
<!--          @click-left="onClickLeft"-->
<!--          style=""-->
<!--      />-->
      <div class="top-input">
        <img :src="inputSearch" style="width: 20px;margin-left: 10px" alt="">
        <input type="search" @keyup.enter="searchPush(text)" @input="getRecruitmentSearchApi" v-model="text" placeholder="搜索单位/科室/岗位名称" @click="searchClick" class="inputS" style="width: 87%">
      </div>
      <div class="top-tag" v-if="selectValue !== ''">
        <div class="top-tag-div" :style="{color: selectTag === item ? '#0B8EEE' : 'black'}" @click="tagChange(item)" v-for="(item, index) in tagList" :key="index">
          {{item}}
          <div v-if="selectTag === item" class="selectTg"></div>
        </div>
      </div>
    </div>
    <template v-if="text === ''  && !isLink">
      <div class="no_text_div">
        <div class="no_text_div_content">
          <div class="no_text_div_content_title">搜索指定内容</div>
          <div class="no_text_div_content_link">
            <span @click="linkClick('进修')">进修</span><span class="fenge"></span><span @click="linkClick('招聘')">招聘</span><span class="fenge"></span><span @click="linkClick('培训')">培训</span>
          </div>
        </div>
      </div>

      <div class="no_text_div_history" v-if="historyList.length">
        <div class="no_text_div_title">搜索历史</div>
        <div class="no_text_div_history_tags">
          <div class="no_text_div_history_tags_div" v-for="(item, index) in historyList" @click="searchPush(item.key)" :key="index">{{item.key}}</div>
        </div>
      </div>

      <div class="no_text_div_hot">
        <div class="no_text_div_title">搜索热词</div>
        <div class="no_text_div_hot_tags">
          <div class="no_text_div_hot_tags_div" @click="searchPush(item.key)" v-for="item in hotList" :key="item.id">
            <div class="no_text_div_hot_tags_div_title">{{item.key}}</div>
            <img :src="hotImg" style="width: 15px;margin-left: 10px">
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="text !== '' || isLink">
      <template v-if="selectValue === ''">
        <div style="display: flex;justify-content: center;" v-if="spinn">
          <van-loading />
        </div>
        <div class="yes_text_box" v-else>
          <div class="yes_text_title">搜索「{{text}}」</div>
          <div class="yes_text_box_body" v-for="(item, index) in tableData" :key="index + '&'" @click="searchPush(item)">
            <div class="yes_text_box_body_div">
              <img :src="inputSearch" style="width: 20px;margin-left: 20px;margin-right: 15px" alt="">
              <div>{{item.split(text)[0]}}<span style="color: red">{{text}}</span>{{item.split(text)[1]}}</div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div v-if="listData.length === 0 && !loading" style="background-color: #F9F9F9" :style="{height: noTextHeight}">
          <div style="font-size: 18px;color: #999999;min-height: 130px;display: flex;    justify-content: center;
    align-items: center;">
            没有找到相关内容！
          </div>
        </div>
        <van-list
            v-else
            style="margin-top: 10px;"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <div class="list_tag" v-for="(item, index) in listData" :key="index +  '&'" @click="clickRouter(item)">
            <template v-if="item.type === 'education'">
              <span class="list_tag_title_JX">进修</span>
              <span>
              <span v-if="item.createdByType === 'department'">[{{item.educationHospitalDepartment[0].name}}]</span>
              {{item.title}}</span>
              <span class="list_tag_title_JI" v-if="item.isImpatient === 1" style="background-color: #FF5075;">急</span>
              <span class="list_tag_title_JI" v-if="item.isNew === 1" style="background-color: #E0D852;">新</span>
              <div style="display: flex;margin-top: 7px" v-if="item.createdByType !== 'department'">
                <div style="color: #999999;font-size: 10px;" v-for="(educationHospital, index) in item.educationHospitalDepartment" :key="educationHospital.id">
                  <span v-if="index !== 0">&nbsp;|&nbsp; </span> {{educationHospital.name}}
                </div>
              </div>
              <div style="display: flex;flex-wrap: wrap;">
                <div class="list_tag_tag1" v-if="item.cycle">
                  {{item.cycle}}
                </div>
                <div class="list_tag_tag1" v-if="item.education">
                  {{item.education}}
                </div>
                <div class="list_tag_tag1" v-if="item.stay">
                  {{item.stay}}
                </div>
                <template v-if="item.createdByType === 'department'">
                  <div class="list_tag_tag1" v-for="(tag, index) in item.educationHospitalDepartment" :key="index + '&'">
                    {{tag.name}}
                  </div>
                </template>
                <div class="list_tag_tag1" v-for="(tag, index) in item.tags" :key="index + '*'">
                  {{tag}}
                </div>
              </div>

              <div class="list_tag_time">
                报名时间：{{moment(item.startTime).format('YYYY年MM月DD日')}} ~ {{moment(item.startTime).format('YYYY') === moment(item.endTime).format('YYYY') ? moment(item.endTime).format('MM月DD日') : moment(item.endTime).format('YYYY年MM月DD日')}} &nbsp;<span :style="{color: computedTime(item) === '未开始'? '#0091FC' : '#FD5F39'}">{{computedTime(item)}}</span>
              </div>
            </template>

            <template v-else-if="item.type === 'recruit'">
              <span class="list_tag_title_ZP">招聘</span>
              <span>
              <span>[{{item.educationHospitalDepartment[0].name}}]</span>
              {{item.title}}</span>
              <span class="list_tag_title_JI" v-if="item.isImpatient === 1" style="background-color: #FF5075;">急</span>
              <span class="list_tag_title_JI" v-if="item.isNew === 1" style="background-color: #E0D852;">新</span>
              <div style="font-size: 14px;color: red;margin-top: 5px">
                {{item.salary}}
              </div>
              <div style="display: flex;flex-wrap: wrap;">
                <div class="list_tag_tag1" v-if="item.salary">
                  {{item.salary}}
                </div>
                <div class="list_tag_tag1" v-if="item.experience">
                  {{item.experience}}
                </div>
                <div class="list_tag_tag1" v-if="item.education">
                  {{item.education}}
                </div>
                <div class="list_tag_tag1" v-if="item.city">
                  {{item.city}}
                </div>
                <div class="list_tag_tag1" v-for="(tag, index) in item.tags" :key="index + '*'">
                  {{tag}}
                </div>
              </div>
              <div style="margin-top: 10px;display: flex;align-items: center;font-size: 11px;color: #999999;    justify-content: space-between;">
              <span style="display: flex;    align-items: center;"><img :src="item.hospital.logo" style="width: 30px;height: 30px;">
              <span style="margin-left: 5px">{{item.hospital.name}}</span></span>
                <span style="float: right">{{moment(item.publishTime).format('YYYY年MM月DD日')}}发布</span>
              </div>
            </template>

            <template v-else-if="item.type === 'train'">
              <span class="list_tag_title_PX">培训</span>
              <span>
              {{item.title}}</span>
              <span class="list_tag_title_JI" v-if="item.isImpatient === 1" style="background-color: #FF5075;">急</span>
              <span class="list_tag_title_JI" v-if="item.isNew === 1" style="background-color: #E0D852;">新</span>
              <!--            <div style="font-size: 14px;color: red;margin-top: 5px">-->
              <!--              {{item.salary}}-->
              <!--            </div>-->
              <div style="display: flex;flex-wrap: wrap;">
                <div class="list_tag_tag1" v-if="item.cycle">
                  {{item.cycle}}
                </div>
                <div class="list_tag_tag1" v-if="item.trainingExpenses">
                  {{item.trainingExpenses}}
                </div>
                <div class="list_tag_tag1" v-if="item.stay">
                  {{item.stay}}
                </div>
                <div class="list_tag_tag1" v-for="(tag, index) in item.tags" :key="index + '*'">
                  {{tag}}
                </div>
              </div>
              <div style="margin-top: 10px;display: flex;    flex-direction: column;font-size: 11px;color: #999999;">
                <div>
                  {{item.abstract2}}
                </div>
                <div>
                  报名时间：{{moment(item.startTime).format('YYYY年MM月DD日')}} ~ {{moment(item.startTime).format('YYYY') === moment(item.endTime).format('YYYY') ? moment(item.endTime).format('MM月DD日') : moment(item.endTime).format('YYYY年MM月DD日')}} &nbsp;<span :style="{color: computedTime(item) === '未开始'? '#0091FC' : '#FD5F39'}">{{computedTime(item)}}</span>
                </div>
              </div>
            </template>
          </div>
        </van-list>
      </template>


    </template>
  </div>
</template>

<script>
import inputSearch from "@/assets/image/Recruitment/search.png";
import {
  getRecruitmentHistoryApi,
  getRecruitmentHotApi,
  getRecruitmentSearchApi,
  getRecruitmentSearchOneApi, postRecruitmentMaiDianApi
} from "@/service/recruitmentApi";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import hotImg from '@/assets/image/Recruitment/fire.png'
import moment from "moment";
import {Toast} from "vant";
export default {
  name: "Search",
  data () {
    return {
      moment,
      loading: '',
      finished: false,
      tagList: ['全部', '进修', '招聘', '培训'],
      selectTag: '全部',
      text: '',
      inputSearch,
      hotList: [],
      historyList: [],
      historyPageSize: 10,
      hotImg,
      tableData: [],
      listData: [],
      spinn: false,
      selectValue: '',
      curPage: 0,
      isLink: false,
    }//dataReturn
  },
  mounted() {
    const inputS = document.getElementsByClassName('inputS')[0]
    inputS.focus()
  },
  watch: {
    '$route.path' : {
      immediate: false,
      handler(to, form) {
        if (to && to !== '/recruitmentDetails' && form && form === '/recruitmentSearch') {
          this.selectTag = '全部'
          this.listData = []
          this.curPage = 0
          this.finished = false
          this.selectValue = ''
          this.text =  ''
          this.loading = false
          this.isLink = false
        } else if (to && to === '/recruitmentSearch' && form && form !== '/recruitmentSearch' && form !== '/recruitmentDetails') {
          const inputS = document.getElementsByClassName('inputS')[0]
          inputS.focus()
        }
      }
    }
  },
  async created() {
    await this.test();
    await this.getHotListOrHistoryList()
  },
  methods: {
    async addMaiDian (contentId) {
      const data = {
        location: '',
        contentId,
        type: 'EDUCATION'
      }
      if (this.selectTag === '全部') {
        data.location = 'EDUCATION_SEARCH_ALL'
      } else if (this.selectTag === '进修') {
        data.location = 'EDUCATION_SEARCH_EDUCATION'
      } else if (this.selectTag === '招聘') {
        data.location = 'EDUCATION_SEARCH_RECRUIT'
      } else if (this.selectTag === '培训') {
        data.location = 'EDUCATION_SEARCH_TRAIN'
      }
      await postRecruitmentMaiDianApi(data)
    },
    computedTime (item) {
      // 获取 item.endTime 对应的 Moment 对象
      const endTimeMoment = moment(item.endTime);

      // 获取当前日期的 Moment 对象，包括时间部分设置为0（确保计算包含今天）
      const todayStartMoment = moment().startOf('day');

      // 计算 endTime 和今天开始的时间点之间的差异，单位为天，+1 表示包含今天
      const day = endTimeMoment.diff(todayStartMoment, "days") + 1;
      const today = moment(); // 获取当前日期的 Moment 对象
      const isStart = moment(item.startTime).isAfter(today); // 比较 someDate 是否晚于今天
      if (isStart) {
        return '未开始'
      } else if (day > 10) {
        return '报名中'
      } else if (day <= 10) {
        return '剩余' + day + '天截止报名'
      }
    },
    async onLoad () {
      this.curPage += 1
      this.loading = true
      const data = {
        key: this.text,
        curPage: this.curPage,
        pageSize: 10
      }
      switch (this.selectTag) {
        case "全部":
          data.type = ''
              break;
        case "进修":
          data.type = 'education'
              break;
        case "招聘":
          data.type = 'recruit'
              break;
        case "培训":
          data.type = 'train'
              break;
      }
      const res = await getRecruitmentSearchOneApi(data)
      if (res.code === 0) {
        this.listData = [...this.listData, ...res.data]
         if (res.count <= this.listData.length) {
            this.finished = true
          }
      }
      this.loading = false
    },
    tagChange (name) {
      if (this.loading) {
        return
      }
      this.selectTag = name
      this.listData = []
      this.curPage = 0
      this.finished = false
      this.loading = true
      this.onLoad()
    },
    async searchPush (item) {
      if (item === '') {
        Toast('输入搜索关键字~')
        return
      }
      this.selectTag = '全部'
      this.listData = []
      this.curPage = 0
      this.finished = false
      this.selectValue = item
      this.text = item
      this.loading = true
      await this.onLoad()
    },
    async getRecruitmentSearchApi () {
      this.selectValue = ''
      this.isLink = false
      this.spinn = true
      if (this.text === '') {
        this.tableData = []
        return
      }
      const data = {
        key: this.text
      }
      const res = await getRecruitmentSearchApi(data)
      if (res.code === 0) {
        this.tableData = res.data
        this.spinn = false
      }
    },
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
          this.$route.query.memberId,
          this.$route.query.channel
      );
      this.channel = arrays.os;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    searchClick () {

    },
    async getHotListOrHistoryList () {
      const res = await getRecruitmentHotApi()
      if (res.code === 0) {
        this.hotList = res.data
      }

      const history = await getRecruitmentHistoryApi(this.historyPageSize)
      if (history.code === 0) {
        this.historyList = history.data
      }
    },
    clickRouter (item) {
      this.addMaiDian(item.id)
      const data = {
        channel: this.$router.currentRoute.query.channel,
        memberId: this.$router.currentRoute.query.memberId,
        id: item.id
      }
      this.$router.push({path: '/recruitmentDetails', query: data})
    },
    async linkClick (item) {
      this.selectTag = item
      this.isLink = true
      this.selectValue = item
      this.listData = []
      this.curPage = 0
      this.finished = false
      this.spinn = true
      await this.onLoad()
    },
    onClickLeft () {
      if (this.text !== '' || this.isLink) {
        this.selectTag = '全部'
        this.listData = []
        this.curPage = 0
        this.finished = false
        this.selectValue = ''
        this.text =  ''
        this.loading = false
        this.isLink = false
        const inputS = document.getElementsByClassName('inputS')[0]
        inputS.focus()
        return
      }
      this.$router.back()
    },
  },

  // computed: {
  //   noTextHeight: function () {
  //     const search = document.getElementsByClassName('search')[0]
  //     const searchHeight = search.offsetHeight
  //     const documentHeight = document.documentElement.offsetHeight
  //     console.log(searchHeight)
  //     console.log(documentHeight)
  //     return documentHeight - searchHeight + 'px'
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.box {
  min-height: 100vh;
}
.search {
  position: sticky;
  top: 0;
  background-color: white;
}
.top-input {
  margin: 5px 20px 10px 20px;
  background-color: #F5F4F4;
  border-radius: 12px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}
.inputS {
  border: 0;
  background-color: #F5F4F4;
  /*caret-color: transparent;*/
}
.searchText {

}
.no_text_div {
  background-color: white;
  margin-top: 15px;
  .no_text_div_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #eaeaea;
    margin: 0 20px;
    padding-bottom: 10px;
    .no_text_div_content_title {
      font-size: 13px;
      color: #999999;
    }
    .no_text_div_content_link {
      margin-top: 5px;
      //font-size: 20px;
      color: #0091FC;
    }
  }
}

.no_text_div_history {
  padding: 20px;
  margin-top: 10px;

  .no_text_div_history_tags {
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;

    .no_text_div_history_tags_div {
      margin-right: 15px;
      margin-bottom: 10px;
      font-size: 14px;
      padding: 4px 8px;
      border-radius: 5px;
      color: #999999;
      border: 1px solid #999999;
    }
  }
}

.no_text_div_hot {
  padding: 20px;
  margin-top: 10px;

  .no_text_div_hot_tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .no_text_div_hot_tags_div {
      width: 45%;
      font-size: 13px;
      display: flex;
      //justify-content: space-between;
      //align-items: center;
      margin-right: 15px;
      margin-bottom: 15px;

      .no_text_div_hot_tags_div_title {
        //max-width: 95%;
        white-space: nowrap; /* 禁止文本换行 */
        overflow: hidden;    /* 隐藏超出的内容 */
        text-overflow: ellipsis; /* 超出的部分显示为省略号 */
      }
    }
  }
}

.yes_text_box {
  padding: 0 20px;
  //overflow: scroll;
  .yes_text_box_body {
    margin-top: 20px;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;

    .yes_text_box_body_div {
      display: flex;
      align-items: center;
    }
  }
}

.fenge {
  height: 100%;
  width: 2px;
  margin: 08px;
  border: 1px solid #999999;
}
.no_text_div_title {
  font-weight: 800;
  font-family: SimHei;
  font-size: 17px;
}

.yes_text_title {
  font-family: SimHei;
  font-size: 17px;
}
.top-tag {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  font-weight: 800;
  font-family: SimHei;
  margin: 20px 0 0 0;
  padding-bottom: 5px;
  //position: sticky;
  //top: 0;
}
.top-tag-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.selectTg {
  width: 100%;
  height: 4px;
  background-color: #0191FB;
  margin-top: 3px;
}
.list_tag {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  margin: 10px 15px 0 15px;
  box-shadow: 0 0 5px #eaeaea; /* 水平偏移 垂直偏移 模糊半径 阴影颜色 */
  .list_tag_title_JX {
    float: left;
    display: inline-block;
    margin: 2.5px 7px 0 0;
    width: 34px;
    height: 16px;
    font-family: SimHei;
    font-weight: 800;
    line-height: 16px;
    text-align: center;
    color: white;
    border-radius: 3px;
    background: linear-gradient(to left, #50E094, #16ba63);
    box-shadow: 0 0 5px 2px rgba(22, 186, 99, .3);
    font-size: 10px;
  }
  .list_tag_title_ZP {
    float: left;
    display: inline-block;
    margin: 2.5px 7px 0 0;
    width: 34px;
    height: 16px;
    font-family: SimHei;
    font-weight: 800;
    line-height: 16px;
    text-align: center;
    color: white;
    border-radius: 3px;
    box-shadow: 0 0 5px 2px rgba(253, 151, 179, .3);
    background: linear-gradient(to left, #FFC3B7, #fd97b0);
    font-size: 10px;
  }
  .list_tag_title_PX {
    float: left;
    display: inline-block;
    margin: 2.5px 7px 0 0;
    width: 34px;
    height: 16px;
    font-family: SimHei;
    font-weight: 800;
    line-height: 16px;
    text-align: center;
    color: white;
    border-radius: 3px;
    box-shadow: 0 0 5px 2px rgba(239, 187, 106, .3);
    background: linear-gradient(to left, #FFD595, #efbb6a);
    font-size: 10px;
  }

  .list_tag_title_JI {
    display: inline-block;
    margin-left: 5px;
    //padding: 0 3px;
    //margin-top: -5px;
    color: white;
    width: 20px;
    text-align: center;
    //height: 20px;
    font-size: 13px;
    border-radius: 6px;
  }

  .list_tag_tag1 {
    margin-right: 10px;
    padding: 3px 7px;
    color: #666666;
    font-size: 12px;
    background-color: #F2F2F2;
    margin-top: 7px;
    border-radius: 2px;
  }
}
.list_tag_time {
  color: #999999;
  font-size: 10px;
  padding-top: 5px;
}
::v-deep .van-nav-bar .van-icon {
  color: #999999;
}
input[type="search"]::-webkit-search-decoration,

input[type="search"]::-webkit-search-cancel-button {

  display: none;

}
input[type="search"]{-webkit-appearance:none;}
input::-webkit-search-cancel-button {display: none;}
</style>